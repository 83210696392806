<template>
    <div class="report-box">
        <NavItem width="16.6666667%" :links="factorLinks" :box_height="box_height" ></NavItem>

        <router-view :box_height="0"></router-view>

    </div>
</template>

<script>
import NavItem from "_c/navItem";
import {factorLinks} from "@/views/digital-factory/methods/data";


export default {
	name: "data-report",
	components: {
		NavItem
	},
	data() {
		return {
			factorLinks
		}
	},
	computed: {
		box_height() {
			return Math.ceil(this.factorLinks.length / 2) * 50 + 10 + 'px'
		},
		path() {
			return this.$route.fullPath
		}
	}

}
</script>

<style scoped lang="less">
.report-box {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  & > div {
    width: calc(100% - 120px);
    height: 100%;
    display: flex;
  }
}
</style>
